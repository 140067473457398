
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































































































$bp: 'm';
$br: 8px;

.cta-stories__cta ::v-deep {
  .cta-parallax__picture {
    background: $c-mustard;
  }

  .cta-parallax__content {
    @extend %ff-alt;
    @extend %fw-medium;

    padding: $spacing * 2;
  }

  .cta-parallax__content__title {
    color: $c-mustard;
  }

  .cta-parallax__picture__element {
    left: 10%;
    width: 80%;
  }

  @include mq(l) {
    .cta-parallax__picture {
      width: 50%;
      margin-right: 0;
    }

    .cta-parallax__content {
      padding: $spacing * 3 $spacing * 4.5 $spacing * 2.5 $spacing * 3.5;
    }

    .cta-parallax__picture__element {
      left: 0;
      width: 100%;
    }
  }
}

.cta-stories__button {
  margin-top: $spacing;

  @include mq(l) {
    margin-top: $spacing * 2;
  }
}
